/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./smoothscroll";
 *   Alpine.data('smoothscroll', scrollTo);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="smoothscroll('selector')" />
 *
 *   With an offset:

 *   <div x-data="smoothscroll('selector', 'offsetSelector')" />
 *
 * @param selector
 * @param offsetSelector
 * @returns {{init(): void}}
 */
export default (selector, offsetAmount = 0) => ({
  init() {
    if(!selector) {
      return;
    }

    const el = document.querySelector(selector);
    if(!el) {
      return;
    }

    if(offsetAmount) {
      this.$el.addEventListener('click', () => {
        window.scrollTo({
          behavior: 'smooth',
          top: el.getBoundingClientRect().top -
               document.body.getBoundingClientRect().top -
               offsetAmount
        });
      });
    } else {
      this.$el.addEventListener('click', () => {
        el.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  },
});
