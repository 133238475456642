import download from 'downloadjs';

export default (pdf, filename, offsetAmount = 0) => ({
  formData: null,
  done: false,
  downloading: false,
  submitting: false,

  init() {
    this.$el.setAttribute('scrolling', 'no');
    this.$el.style.height = '600px';
  },

  submit() {
    if (this.submitting) return;
    this.submitting = true;

    console.log('submit handler');

    let toTop = this.$el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offsetAmount;

    console.log(toTop);

    window.scrollTo({
      behavior: 'smooth',
      top: toTop
    });

    if(!pdf) return;
    this.downloading = true;

    var x=new XMLHttpRequest();
    x.open( "GET", pdf , true);
    x.responseType="blob";
    x.onload= function(e){
      self.done = true;
      download(e.target.response, filename, "application/pdf");
    };
    x.send();
  },

  onMessage($event) {
    console.log($event.data);
    if(typeof $event.data === 'string' && $event.data.startsWith('zendesk-form:')) {
      // zendesk-form:ready
      // zendesk-form:pending
      // zendesk-form:success
      // zendesk-form:failed
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : $event.data
      });

      if($event.data === 'zendesk-form:success') {
        window.history.replaceState(null, '', 'success');
        this.submit();
      }
    }
    if($event.data.type !== undefined && typeof $event.data.type === 'string' && $event.data.type == 'zendesk-form:dimensions') {
      console.log('size handler');
      this.$el.style.height = $event.data.data.height + 'px';
    }
  }
});
